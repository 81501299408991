import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import './App.css';
import Acknowledgements from "./components/Acknowledgements";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Acknowledgements" element={<Acknowledgements />} />
          <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
