import React from "react";
import { Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from "react-router-dom";

function Contact() {
    return (
        <Container className="mt-5">
            <Link to="/" className="btn btn-outline-primary mb-4">
                <FontAwesomeIcon icon={solid("arrow-left")} className="me-2" />
                Back to Home
            </Link>
            
            <h1>Contact Us</h1>
            <p className="lead mb-5">Get in touch with the PlethorAI team</p>
            
            <div className="row justify-content-center">
                <div className="col-md-4 mb-4">
                    <Card className="h-100 text-bg-dark">
                        <Card.Body className="text-center">
                            <FontAwesomeIcon icon={solid("envelope")} size="2x" className="mb-3" />
                            <Card.Title>Contact</Card.Title>
                            <Card.Text>
                                publish at thisdomain
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-4 mb-4">
                    <Card className="h-100 text-bg-dark">
                        <Card.Body className="text-center">
                            <FontAwesomeIcon icon={solid("location-dot")} size="2x" className="mb-3" />
                            <Card.Title>PLETHORAI TECH PRIVATE LIMITED</Card.Title>
                            <Card.Text>
                                2 Venture Drive <br />
                                #24-01 <br />
                                Singapore 608526
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-4 mb-4">
                    <Card className="h-100 text-bg-dark">
                        <Card.Body className="text-center">
                            <FontAwesomeIcon icon={solid("clock")} size="2x" className="mb-3" />
                            <Card.Title>Business Hours</Card.Title>
                            <Card.Text>
                                Monday - Friday<br />
                                9:00 AM - 5:00 PM SGT
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
{/* 
            <div className="mt-5 text-center">
                <h3>Connect With Us</h3>
                <p className="lead">
                    For business inquiries, partnership opportunities, or general questions,
                    please email us at contact@plethorai.com
                </p>
            </div> */}
        </Container>
    );
}

export default Contact; 